import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./navbar.css";
import $ from 'jquery';
import Logo from "../../img/logo/ponent_explorers_nav.png"

export const Navbar = (props) => {
    const {t, i18n} = useTranslation();

    const onMouseEnter = (event) => {
        const dropdownMenu = event.currentTarget.querySelector('.dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.remove("hide");
        }
    };
    const onMouseLeave = (event) => {
        const dropdownMenu = event.currentTarget.querySelector('.dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.add("hide");
        }
    };

    useEffect(() => {
        $(document).ready(function () {
            var $navbar = $('#menu');
            var $dropdowns = $('.dropdown-menu');
            $(window).scroll(function (event) {
                var $current = $(this).scrollTop();
                if ($current > 0) {
                    $navbar.addClass('navbar-colored');
                    $dropdowns.each(function () {
                        $(this).addClass('dropdown-menu-colored');
                    });
                } else {
                    $navbar.removeClass('navbar-colored');
                    $dropdowns.each(function () {
                        $(this).removeClass('dropdown-menu-colored');
                    });
                }
            });
        })
    }, []);

    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#menu-navbar-collapse"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">
                        <img style={{height: "40px"}} src={Logo}/>
                    </a>{" "}
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="menu-navbar-collapse"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <a href="#cicloturisme" className="page-scroll">
                                {t("cicloturisme")}<i className={"fa fa-caret-down"} style={{marginLeft: "10px"}}/>
                            </a>
                            <ul className={"dropdown-menu hide"}>
                                <li className="nav-item">
                                    <a className="page-scroll dropdown-item" href="#toursfets">{t("toursfets")}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="page-scroll dropdown-item" href="#amida">{t("amida")}</a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <a href="#apeu" className="page-scroll">
                                {t("apeu")}<i className={"fa fa-caret-down"} style={{marginLeft: "10px"}}/>
                            </a>
                            <ul className={"dropdown-menu hide"}>
                                <li className="nav-item">
                                    <a className="page-scroll dropdown-item" href="#freetour">{t("freetour")}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="page-scroll dropdown-item" href="#privtour">{t("privtour")}</a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href="#gallery" className="page-scroll">
                                {"Galeria"}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#portfolio" className="page-scroll">
                                {t("about.about")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#footer" className="page-scroll">
                                {t("contacte.contacte")}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
