import React from "react";
import {useTranslation} from "react-i18next";
import {SectionSubtitle} from "../../section-title/section-subtitle";
import {Card} from "../../card/card";
import "./fets.css";

import trenLlacsCamarasa from "../../../img/cicloturisme/trenLlacs/camarasa.jpg";
import trenLlacsRuta from "../../../img/cicloturisme/trenLlacs/ruta.jpg";
import trenLlacsTren from "../../../img/cicloturisme/trenLlacs/tren.jpg";
import montsecPrat from "../../../img/cicloturisme/montsec/prat.jpg";
import montsecCarretera from "../../../img/cicloturisme/montsec/carretera.jpg";
import montsecMirador from "../../../img/cicloturisme/montsec/mirador.jpg";
import montsecRiu from "../../../img/cicloturisme/montsec/riu.jpg";
import vallFoscaEstany from "../../../img/cicloturisme/vallFosca/estany.jpg";
import vallFoscaEstany1 from "../../../img/cicloturisme/vallFosca/estany1.jpg";
import vallFoscaPista from "../../../img/cicloturisme/vallFosca/Pista.jpg";
import vallFoscaVista from "../../../img/cicloturisme/vallFosca/vista.jpg";

import dadesTecniquesTLL_ca from "../../../data/trenLlacs/ca/DadesTecniques.json"
import dadesTecniquesTLL_es from "../../../data/trenLlacs/es/DadesTecniques.json"
import dadesTecniquesTLL_en from "../../../data/trenLlacs/en/DadesTecniques.json"
import dadesTecniquesVF_ca from "../../../data/vallFosca/ca/DadesTecniques.json"
import dadesTecniquesVF_es from "../../../data/vallFosca/es/DadesTecniques.json"
import dadesTecniquesVF_en from "../../../data/vallFosca/en/DadesTecniques.json"

const dadesTecniquesTLL = navigator.language.includes("en") ? dadesTecniquesTLL_en : navigator.language.includes("es") ? dadesTecniquesTLL_es : dadesTecniquesTLL_ca;
const dadesTecniquesVF = navigator.language.includes("en") ? dadesTecniquesVF_en : navigator.language.includes("es") ? dadesTecniquesVF_es : dadesTecniquesVF_ca;
// const dadesTecniquesTLL = dadesTecniquesTLL_ca;
// const dadesTecniquesVF = dadesTecniquesVF_ca;

export const Fets = (props) => {
    const {t, i18n} = useTranslation();

    return (
        <div id="toursfets">
            <SectionSubtitle title={t("toursfets")}/>
            <Card id={"carouselImatgesTrenLlacs"} href={"#footer"}
                  imatges={[trenLlacsCamarasa, trenLlacsRuta, trenLlacsTren]} textEslogan={t("trenLlacs.eslogan1")}
                  textTitolPrincipal={t("trenLlacs.eslogan2")} textContingut={t("trenLlacs.contingut")}
                  textBoto={t("trenLlacs.esloganBoto")}
                  dadesTecniques={dadesTecniquesTLL}
            />
            <Card id={"carouselImatgesMontsec"} href={"#footer"}
                  imatges={[montsecPrat, montsecCarretera, montsecMirador, montsecRiu]}
                  textEslogan={t("montsec.eslogan1")} textTitolPrincipal={t("montsec.eslogan2")}
                  textContingut={t("montsec.contingut")} textBoto={t("montsec.esloganBoto")}ç
            />
            <Card id={"carouselImatgesVallFosca"} href={"#footer"}
                  imatges={[vallFoscaEstany, vallFoscaEstany1, vallFoscaPista, vallFoscaVista]}
                  textEslogan={t("vallfosca.eslogan1")} textTitolPrincipal={t("vallfosca.eslogan2")}
                  textContingut={t("vallfosca.contingut")} textBoto={t("vallfosca.esloganBoto")}
                  dadesTecniques={dadesTecniquesVF}
            />
        </div>
    )
}