import './App.css';
import './i18n';
import {useTranslation} from 'react-i18next';
import React, {useEffect} from "react";
import {Navbar} from "./components/navbar/navbar";
import {Header} from "./components/header/header";
import {Cicloturisme} from "./components/cicloturisme/cicloturisme";
import {Apeu} from "./components/apeu/apeu";
import {Portfolio} from "./components/portfolio/portfolio";
import {Footer} from "./components/footer/footer";
import {Gallery} from "./components/gallery/gallery";

function App() {
    const {t, i18n} = useTranslation();
    // i18n.changeLanguage("ca");
    i18n.changeLanguage(navigator.language);

    // useEffect(() => {
    //     i18n.changeLanguage("ca");
    //     // i18n.changeLanguage(navigator.language);
    // });

    return (
        <div className="App">
            {/*<Navbar/>*/}
            <Header/>
            {/*<Cicloturisme/>*/}
            {/*<Apeu/>*/}
            {/*<Gallery/>*/}
            {/*<div id={"portfolioDivider"}/>*/}
            {/*<Portfolio/>*/}
            {/*<div id={"footerDivider"}/>*/}
            {/*<Footer/>*/}
        </div>
    );
}

export default App;
